import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'
import { MixerInputState } from '@customTypes/cloudmix/sessions/pads'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { applyPatches, enableES5 } from 'immer'
import { RootState } from '@lib/store'
import {
  COMPONENT_TYPES,
  destinationComponentTypeSchema,
  sourceContributorAndMixerComponentTypeSchema,
} from '@customTypes/cloudmix/sessions/components/base'

enableES5()

type ByWorkflowSessionId<T> = {
  byWorkflowSessionId: Record<WorkflowSessionId, T>
}

type InputStates = {
  byId: Record<string, MixerInputState>
  allIds: string[]
}

type Components = {
  byId: Record<string, any>
  allIds: string[]
}

type Inputs = {
  byId: Record<string, any>
  allIds: string[]
  byComponentId: Record<string, string[]>
}

type Links = {
  byId: Record<string, any>
  allIds: string[]
  bySource: Record<string, string>
  byTarget: Record<string, string>
}

type WorkflowSessionStates = {
  components: ByWorkflowSessionId<Components>
  inputs: ByWorkflowSessionId<Inputs>
  links: ByWorkflowSessionId<Links>
  inputState: ByWorkflowSessionId<InputStates>
}

export const initialState: WorkflowSessionStates = {
  components: {
    byWorkflowSessionId: {},
  },
  inputs: {
    byWorkflowSessionId: {},
  },
  links: {
    byWorkflowSessionId: {},
  },
  inputState: {
    byWorkflowSessionId: {},
  },
}

const workflowSessionState = createSlice({
  name: 'workflowSessionState',
  initialState,
  reducers: {
    updateFullState: (state, action) => {
      const { workflowSessionId, components, links, inputs, inputState } =
        action.payload

      state.components.byWorkflowSessionId[workflowSessionId] = components
      state.inputs.byWorkflowSessionId[workflowSessionId] = inputs
      state.links.byWorkflowSessionId[workflowSessionId] = links
      state.inputState.byWorkflowSessionId[workflowSessionId] = inputState
    },
    patchState: (state, action) => {
      const { workflowSessionId, patches } = action.payload

      try {
        applyPatches(
          state,
          patches.map((patch) => ({
            ...patch,
            path: [
              patch.path[0],
              'byWorkflowSessionId',
              workflowSessionId,
              ...patch.path.slice(1),
            ],
          }))
        )
      } catch (e) {
        console.error(e)
      }
    },
  },
})
export const { updateFullState, patchState } = workflowSessionState.actions
export default workflowSessionState.reducer

const emptyObj = {}
const emptyArr = []

export const selectInputStateById = (state, workflowSessionId, id) =>
  state.workflowSessionState?.inputState?.byWorkflowSessionId?.[
    workflowSessionId
  ]?.byId[id] ?? emptyObj

export const selectInputBySessionId = createSelector(
  [
    (state: RootState) => state.workflowSessionState.inputs.byWorkflowSessionId,
    (_: any, workflowSessionId: WorkflowSessionId) => workflowSessionId,
  ],
  (inputs, workflowSessionId) => inputs[workflowSessionId]?.byId || {}
)

export const selectInputById = createSelector(
  [
    selectInputBySessionId,
    (_: any, _2: WorkflowSessionId, inputId: string) => inputId,
  ],
  (inputs, inputId) => inputs[inputId]
)

export const selectInputsByComponentId = createSelector(
  [
    (state: RootState) => state.workflowSessionState.inputs.byWorkflowSessionId,
    (_: RootState, workflowSessionId: WorkflowSessionId) => workflowSessionId,
    (_: RootState, _2: WorkflowSessionId, componentId: string) => componentId,
  ],
  (inputsBySession, workflowSessionId, componentId) => {
    const sessionInputs = inputsBySession[workflowSessionId]
    if (!sessionInputs) return []
    return sessionInputs.byComponentId?.[componentId] || []
  }
)

export const selectComponentInputsOrder = createSelector(
  [
    selectInputsByComponentId,
    selectInputBySessionId,
    (_: RootState, workflowSessionId: WorkflowSessionId) => workflowSessionId,
  ],
  (inputIds, inputsById) =>
    inputIds
      .map((inputId) => inputsById[inputId])
      .filter(Boolean) // Remove undefined inputs
      .map((input) => ({
        name: input.name,
        order: input?.video?.order ?? 0,
      }))
      .sort((a, b) => a.order - b.order)
)

export const selectComponentBySessionId = createSelector(
  [
    (state: RootState) =>
      state.workflowSessionState.components.byWorkflowSessionId,
    (_: any, workflowSessionId: WorkflowSessionId) => workflowSessionId,
  ],
  (components, workflowSessionId) => components[workflowSessionId]?.byId || {}
)

export const selectComponentById = createSelector(
  [
    selectComponentBySessionId,
    (_: any, _2: WorkflowSessionId, componentId: string) => componentId,
  ],
  (components, componentId) => components[componentId]
)

export const makeSelectComponentsByType = () =>
  createSelector(
    [
      selectComponentBySessionId,
      (_: any, __: WorkflowSessionId, type: string) => type,
    ],
    (components, type) =>
      Object.values(components).filter((component) => component.type === type)
  )

export const selectLinksBySessionId = createSelector(
  [
    (state: RootState) => state.workflowSessionState.links.byWorkflowSessionId,
    (_: RootState, workflowSessionId: WorkflowSessionId) => workflowSessionId,
  ],
  (linksBySession, workflowSessionId) =>
    linksBySession[workflowSessionId] || {
      byId: {},
      byTarget: {},
      bySource: {},
      allIds: [],
    }
)

export const selectLinkById = createSelector(
  [
    selectLinksBySessionId,
    (_: RootState, _2: WorkflowSessionId, linkId: string) => linkId,
  ],
  (links, linkId) => links.byId[linkId] || emptyObj
)

export const selectLinkByTarget = createSelector(
  [
    selectLinksBySessionId,
    (_: RootState, _2: WorkflowSessionId, targetKey: string) => targetKey,
  ],
  (links, targetKey) => {
    const linkId = links.byTarget[targetKey]
    return linkId ? links.byId[linkId] : emptyObj
  }
)

export const selectLinkBySource = createSelector(
  [
    selectLinksBySessionId,
    (_: RootState, _2: WorkflowSessionId, sourceKey: string) => sourceKey,
  ],
  (links, sourceKey) => {
    const linkId = links.bySource[sourceKey]
    return linkId ? links.byId[linkId] : emptyObj
  }
)

export const makeSelectMutedInputsWithOrder = () =>
  createSelector(
    [
      (state: RootState, workflowSessionId: string, componentId: string) =>
        state.workflowSessionState.inputs.byWorkflowSessionId[workflowSessionId]
          ?.byComponentId[componentId] || emptyArr,
      (state: RootState, workflowSessionId: string) =>
        state.workflowSessionState.inputs.byWorkflowSessionId[workflowSessionId]
          ?.byId || {},
      (_: RootState, _2: string, _3: string, mute: boolean) => mute,
      (_: RootState, _2: string, _3: string, _4: boolean, order: number) =>
        order,
    ],
    (inputIds, inputsById, isMuted, order) =>
      inputIds
        .map((id) => inputsById[id])
        .filter(Boolean)
        .filter(
          (input) =>
            input.audio?.mute === isMuted &&
            (input.video?.order ?? -1) === order
        )
        .map((input) => input.name)
  )

export const makeSelectActiveInputsWithOrder = () =>
  createSelector(
    [
      (state: RootState, workflowSessionId: string, componentId: string) =>
        state.workflowSessionState.inputs.byWorkflowSessionId[workflowSessionId]
          ?.byComponentId[componentId] || emptyArr,
      (state: RootState, workflowSessionId: string) =>
        state.workflowSessionState.inputs.byWorkflowSessionId[workflowSessionId]
          ?.byId || emptyObj,
      (_: RootState, _2: string, _3: string, active: boolean) => active,
      (_: RootState, _2: string, _3: string, _4: boolean, order: number) =>
        order,
    ],
    (inputIds, inputsById, isActive, order) =>
      inputIds
        .map((id) => inputsById[id])
        .filter(Boolean)
        .filter(
          (input) =>
            input.video?.active === isActive &&
            (input.video?.order ?? -1) === order
        )
        .map((input) => input.name)
  )

export const selectMixerComponentWithInputs = createSelector(
  [
    (state: RootState, workflowSessionId: string) =>
      state.workflowSessionState.components.byWorkflowSessionId[
        workflowSessionId
      ]?.byId,
    (_, workflowSessionId: string, componentId: string) => componentId,
    (state: RootState, workflowSessionId: string) =>
      state.workflowSessionState.inputs.byWorkflowSessionId[workflowSessionId],
  ],
  (componentsById, componentId, inputsState) => {
    const component = componentsById?.[componentId]
    if (!component) return null

    const inputIds = inputsState?.byComponentId[componentId] || []
    const inputs = inputIds
      .map((id) => ({
        ...inputsState.byId[id],
      }))
      .filter(Boolean)

    return {
      ...component,
      inputs,
    }
  }
)

export const selectDestinationComponents = createSelector(
  [
    (state: RootState, workflowSessionId: WorkflowSessionId) =>
      state.workflowSessionState.components.byWorkflowSessionId[
        workflowSessionId
      ]?.byId || emptyObj,
  ],
  (componentsById) =>
    Object.values(componentsById).filter(
      (component) =>
        destinationComponentTypeSchema.safeParse(component?.type)?.success
    ) ?? emptyArr
)
