import { Component, ComponentData } from '@features/projects/lib/base/Component'
import { Pad, PadData } from '@features/projects/lib/base/Pad'

/* eslint-disable react/sort-comp */
interface ZixiSourceData extends ComponentData {
  host: string
  port?: number
  streamId: string | null
  maxLatency?: number
  output: PadData
}
export class ZixiSource extends Component {
  type = 'zixiSource'

  host: string

  port?: number

  streamId: string

  maxLatency?: number

  output: Pad

  constructor(data: ZixiSourceData) {
    super(data)
    this.host = data.host
    this.port = data?.port
    this.streamId = data.streamId || ''
    this.maxLatency = data?.maxLatency
    this.output = new Pad(data.output)
  }

  toData() {
    const commonData = super.toData()
    return {
      ...commonData,
      type: this.getType(),
      output: this.output.toData(),
      host: this.host,
      streamId: this.streamId,
      ...(this?.port && { port: this.port }),
      ...(this?.maxLatency && { maxLatency: this.maxLatency }),
    }
  }

  static create(host = '', streamId = '', displayName?: string) {
    const data = {
      host,
      streamId,
      displayName,
      output: new Pad(),
    }
    return new ZixiSource(data)
  }

  patch(data: Partial<ZixiSourceData>) {
    super.patch(data)
    const { output, ...rest } = data
    this.output.patch(output as Pad)
    Object.assign(this, { ...rest })
  }
}
