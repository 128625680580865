import { createSlice } from '@reduxjs/toolkit'
import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'

export const PANELS = {
  AUDIO: 'audio',
  DESTINATIONS: 'destinations',
  SETTINGS: 'settings',
} as const

type Panel = typeof PANELS[keyof typeof PANELS]

type UnmonitoredProjectStreams = {
  [workflowSessionId: WorkflowSessionId]: string[]
}

type ProjectState = {
  audioFollowsVideo: boolean
  destinationsExpanded: boolean
  isLayerReordering: boolean
  focusedPanel: Panel
  defaultRealtimePreviews: boolean
  selectedProjectMixerId?: string
  unmonitoredProjectStreams?: UnmonitoredProjectStreams
}
export const initialState: ProjectState = {
  audioFollowsVideo: false,
  destinationsExpanded: true,
  isLayerReordering: false,
  focusedPanel: PANELS.AUDIO,
  defaultRealtimePreviews: true,
  selectedProjectMixerId: undefined,
  unmonitoredProjectStreams: {},
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    toggleAudioFollowsVideo: (state) => {
      state.audioFollowsVideo = !state.audioFollowsVideo
    },
    toggleDestinationsExpanded: (state) => {
      state.destinationsExpanded = !state.destinationsExpanded
    },
    toggleLayerReorder: (state) => {
      state.isLayerReordering = !state.isLayerReordering
    },
    focusPanel: (state, action: { payload: Panel }) => {
      state.focusedPanel = action.payload
    },
    toggleDefaultRealtimePreviews: (state) => {
      state.defaultRealtimePreviews = !state.defaultRealtimePreviews
    },
    selectMixerInProject: (state, action: { payload: string }) => {
      state.selectedProjectMixerId = action.payload
    },
    addUnmonitoredProjectStream: (
      state,
      action: {
        payload: { workflowSessionId: WorkflowSessionId; streamId: string }
      }
    ) => {
      if (!state.unmonitoredProjectStreams) {
        state.unmonitoredProjectStreams = {}
      }
      // If we're not monitoring any streams, add this stream
      if (!state.unmonitoredProjectStreams[action.payload.workflowSessionId]) {
        state.unmonitoredProjectStreams[action.payload.workflowSessionId] = []
      }
      // If we're already monitoring all streams, don't add this stream
      if (
        state.unmonitoredProjectStreams[
          action.payload.workflowSessionId
        ].includes(action.payload.streamId)
      ) {
        return
      }

      // Otherwise, just add this stream
      state.unmonitoredProjectStreams[action.payload.workflowSessionId].push(
        action.payload.streamId
      )
    },
    setUnmonitoredProjectStreams: (
      state,
      action: {
        payload: { workflowSessionId: WorkflowSessionId; streamIds: string[] }
      }
    ) => {
      if (!state.unmonitoredProjectStreams) {
        state.unmonitoredProjectStreams = {}
      }

      state.unmonitoredProjectStreams[action.payload.workflowSessionId] =
        action.payload.streamIds
    },
    removeUnmonitoredProjectStream: (
      state,
      action: {
        payload: { workflowSessionId: WorkflowSessionId; streamId: string }
      }
    ) => {
      if (!state.unmonitoredProjectStreams) {
        state.unmonitoredProjectStreams = {}
        return
      }
      const streams =
        state.unmonitoredProjectStreams[action.payload.workflowSessionId]
      if (!streams) {
        return
      }
      const index = streams.indexOf(action.payload.streamId)
      if (index === -1) {
        return
      }
      streams.splice(index, 1)
    },
  },
})
export const {
  toggleAudioFollowsVideo,
  toggleDestinationsExpanded,
  toggleLayerReorder,
  focusPanel,
  toggleDefaultRealtimePreviews,
  selectMixerInProject,
  addUnmonitoredProjectStream,
  removeUnmonitoredProjectStream,
  setUnmonitoredProjectStreams,
} = projectSlice.actions
export default projectSlice.reducer

const emptyArr = []
export const selectUnmonitoredProjectStreams = (
  state: { project: ProjectState },
  workflowSessionId: WorkflowSessionId
) => state.project.unmonitoredProjectStreams?.[workflowSessionId] || emptyArr
