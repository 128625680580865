import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getClientTenantAuth from '@lib/firebase/getClientTenantAuth'
import { selectOrgId } from '@features/organisations/slice'
import qs from 'qs'

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.NEXT_PUBLIC_MEDIA_PROJECTS_API_URI}/`,
  prepareHeaders: async (headers) => {
    // Get token from cookie of firebase user?
    const auth = getClientTenantAuth()
    if (auth) {
      await auth?.authStateReady()
      const token = await auth?.currentUser?.getIdToken()
      if (token) {
        headers.set('Authorization', `GipIdToken ${token}`)
      }
    }

    return headers
  },
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: 'indices',
      encode: false,
      allowDots: true,
    }),
})

const baseQueryWithOrgId = (args, api, extraOptions) => {
  const apiState = api.getState()
  const orgId = selectOrgId(apiState)
  let { url } = args
  if (orgId) {
    if (url.search(/\?/g) === -1) {
      url = `${url}?orgId=${orgId}`
    } else if (url.search(/orgId=/g) === -1) {
      url = `${url}&orgId=${orgId}`
    }
  }

  return baseQuery({ ...args, url }, api, extraOptions)
}

export const mediaProjectsApi = createApi({
  reducerPath: 'mediaProjectsApi',
  baseQuery: baseQueryWithOrgId,
  tagTypes: [
    'Project',
    'Workflow',
    'Components',
    'WorkflowSession',
    'ControlRoomSession',
    'CommentaryBox',
    'Distributions',
    'Region',
    'Recording',
    'Clips',
    'Exports',
  ],
  endpoints: () => ({}),
})
