import { z } from 'zod'

export const paginationSchema = z.object({
  pageIndex: z.number().min(0),
  pageSize: z.number().min(1),
  totalCount: z.number().min(0),
})

export const paginationRequestSchema = z.object({
  pageIndex: z.number().min(0).default(0).optional(),
  pageSize: z.number().min(1).default(10).optional(),
})
export type PaginationRequest = z.infer<typeof paginationRequestSchema>

export const timestampsSchema = z.object({
  createdAt: z.number(),
  lastUpdatedAt: z.number(),
})

export const sessionTimestampsSchema = z.object({
  startedAt: z.number(),
  stoppedAt: z.number().nullable(),
})

export const durationRangeRequestSchema = z.object({
  from: z.number(),
  to: z.number(),
})
export type DurationRangeRequest = z.infer<typeof durationRangeRequestSchema>
