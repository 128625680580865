import { z } from 'zod'

import { mixerComponentSchema } from '@customTypes/cloudmix/sessions/components/mixer'
import { webSourceComponentSchema } from '@customTypes/cloudmix/sessions/components/webSource'
import { testSourceComponentSchema } from '@customTypes/cloudmix/sessions/components/testSource'
import { imageSourceComponentSchema } from '@customTypes/cloudmix/sessions/components/imageSource'
import { liveInputSourceComponentSchema } from '@customTypes/cloudmix/sessions/components/liveInputSource'
import { rtmpDestinationComponentSchema } from '@customTypes/cloudmix/sessions/components/rtmpDestination'
import { componentIdSchema } from '@customTypes/cloudmix/sessions/components/base'
import { displayComponentSchema } from '@customTypes/cloudmix/sessions/components/display'
import { contributorComponentSchema } from '@customTypes/cloudmix/sessions/components/contributor'
import { playerSourceComponentSchema } from '@customTypes/cloudmix/sessions/components/playerSource'
import { srtDestinationComponentSchema } from '@customTypes/cloudmix/sessions/components/srtDestination'
import { liveOutputDestinationComponentSchema } from '@customTypes/cloudmix/sessions/components/liveOutputDestination'
import { zixiDestinationComponentSchema } from './zixiDestination'
import { zixiSourceComponentSchema } from './zixiSource'

export const componentSchema = z.discriminatedUnion('type', [
  mixerComponentSchema,
  webSourceComponentSchema,
  rtmpDestinationComponentSchema,
  srtDestinationComponentSchema,
  liveInputSourceComponentSchema,
  liveOutputDestinationComponentSchema,
  testSourceComponentSchema,
  imageSourceComponentSchema,
  displayComponentSchema,
  contributorComponentSchema,
  // playerSourceComponentSchema, // TODO(ajb): Add back when supported by API
  zixiDestinationComponentSchema,
  zixiSourceComponentSchema,
])

export const destinationSchema = z.discriminatedUnion('type', [
  rtmpDestinationComponentSchema,
  srtDestinationComponentSchema,
  liveOutputDestinationComponentSchema,
  zixiDestinationComponentSchema,
  // displayComponentSchema, // TODO(ajb): Add back when supported by API
])

export const sourceSchema = z.discriminatedUnion('type', [
  webSourceComponentSchema,
  liveInputSourceComponentSchema,
  testSourceComponentSchema,
  imageSourceComponentSchema,
  zixiSourceComponentSchema,
  // playerSourceComponentSchema, // TODO(ajb): Add back when supported by API
])
export type SourceComponent = z.infer<typeof sourceSchema>

export const sourceAndContributorComponentSchema = z.union([
  sourceSchema,
  contributorComponentSchema,
])

export type SourceAndContributorComponent = z.infer<
  typeof sourceAndContributorComponentSchema
>

export const sourceAndMixerComponentSchema = z.union([
  sourceSchema,
  mixerComponentSchema,
])
export type SourceAndMixerComponent = z.infer<
  typeof sourceAndMixerComponentSchema
>

export const sourceAndMixerAndContributorComponentSchema = z.union([
  sourceSchema,
  mixerComponentSchema,
  contributorComponentSchema,
])
export type SourceAndMixerAndContributorComponent = z.infer<
  typeof sourceAndMixerAndContributorComponentSchema
>

export type ComponentId = z.infer<typeof componentIdSchema>
export type Component = z.infer<typeof componentSchema>
export type MixerComponent = z.infer<typeof mixerComponentSchema>
export type WebSourceComponent = z.infer<typeof webSourceComponentSchema>
export type RtmpDestinationComponent = z.infer<
  typeof rtmpDestinationComponentSchema
>
export type SrtDestinationComponent = z.infer<
  typeof srtDestinationComponentSchema
>

export type LiveInputSourceComponent = z.infer<
  typeof liveInputSourceComponentSchema
>
export type LiveOutputDestinationComponent = z.infer<
  typeof liveOutputDestinationComponentSchema
>
export type TestSourceComponent = z.infer<typeof testSourceComponentSchema>
export type ImageSourceComponent = z.infer<typeof imageSourceComponentSchema>

export type DisplayComponent = z.infer<typeof displayComponentSchema>
export type ContributorComponent = z.infer<typeof contributorComponentSchema>
// export type PlayerSourceComponent = z.infer<typeof playerSourceComponentSchema> // TODO(ajb): Add back when supported by API

export type ZixiSourceComponent = z.infer<typeof zixiSourceComponentSchema>
export type ZixiDestinationComponent = z.infer<
  typeof zixiDestinationComponentSchema
>

export type DestinationComponent = z.infer<typeof destinationSchema>

export type WithComponentId<T = void> = T & { componentId: ComponentId }
