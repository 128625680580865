import { z } from 'zod'
import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { v4 as uuidv4 } from 'uuid'
import { audioVideoPadSchema } from '../../pads'

const falseyToUndefined = (val: unknown) => val || undefined

export const zixiDestinationComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.zixiDestination)
    .default(COMPONENT_TYPES.zixiDestination),
  host: z.string(),
  port: z.preprocess(falseyToUndefined, z.coerce.number().optional()),
  streamId: z.string().min(1),
  maxLatency: z.preprocess(falseyToUndefined, z.coerce.number().optional()),
  input: audioVideoPadSchema.optional().default(() => {
    const id = uuidv4()
    return { name: id, displayName: id }
  }),
  enabled: z.boolean().default(false),
})
